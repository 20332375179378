<template>
  <div class="creatives-carousel">
    <span v-show="scrollAllowed"
          class="common-scroll-btn"
          :class="{'not-allowed' : !leftScrollAllowed}"
          @click="scroll(-1)">
      <svg-icon v-once icon="arrow-left"/>
    </span>
    <div v-if="this.screenshots?.length || !allLoaded"
         class="screenshots-line-bottom"
         ref="carousel"
         @scroll="markAngles">
      <div class="screenshot" v-for="(screenshot, index) in this.screenshots">
        <div class="screenshots-skeleton-box" v-if="!allLoaded"></div>
        <img v-show="allLoaded"
             @load="handleLoad"
             :src="cropImageToHeight('https://' + screenshot.url, 280, storeKey)"
             @click="showSingle(index)">
      </div>
    </div>
    <div v-else class="text-center">
      <span>😔 No screenshots yet</span>
    </div>
    <span v-show="scrollAllowed"
          class="common-scroll-btn"
          :class="{'not-allowed' : !rightScrollAllowed}"
          @click="scroll(1)">
      <svg-icon v-once icon="arrow-right"/>
    </span>
  </div>

  <vue-easy-lightbox
    :visible="visibleRef"
    :imgs="imgsRef"
    :index="indexRef"
    teleport="body"
    @hide="this.visibleRef = false"
  ></vue-easy-lightbox>
</template>

<script>
import {cropImageToHeight} from "@/utils/utils";
import {nextTick, ref} from "vue";
import {httpRequest} from "@/api";
import VueEasyLightbox from "vue-easy-lightbox";
// todo refactor with ScreenshotsLine
export default {
  name: 'ScreenshotsCarousel',
  components: {VueEasyLightbox},
  props: {
    countryCode: String,
    storeKey: String,
    appId: String,
  },
  data() {
    return {
      scrollAllowed: false,
      leftScrollAllowed: false,
      rightScrollAllowed: false,
      loadedImg: 0,
      screenshots: [],
      allLoaded: false,

      indexRef: ref(0),
      imgsRef: ref([]),
      visibleRef: ref(false),
    };
  },
  async mounted() {
    let result = await httpRequest(
      'GET',
      process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.GET_LATEST_SCREENSHOTS
      + `?country_code=${this.countryCode}&app_id=${this.appId}`
    );
    this.screenshots = result.list;

    if (!this.screenshots?.length) {
      this.allLoaded = true;
    }

    this.markAngles();
  },
  updated() {
    this.markAngles();
  },
  methods: {
    cropImageToHeight,
    showSingle(index) {
      this.imgsRef = this.screenshots.map(item => {
        return {
          src: 'https://' + item.url
        }
      });

      this.indexRef = index;
      this.visibleRef = true;
    },
    async handleLoad() {
      if (this.screenshots.length === 0 || ++this.loadedImg >= Math.round(this.screenshots.length / 2)) {
        this.allLoaded = true;
      }

      await nextTick();
      this.markAngles();
    },
    scroll(factor) {
      let ref = this.$refs.carousel;

      ref.scroll({
        left: ref.scrollLeft + ref.clientWidth * 0.5 * factor,
        behavior: "smooth",
      });
    },
    markAngles() {
      let ref = this.$refs.carousel;
      if (!ref) {
        return;
      }

      let maxScroll = ref.scrollWidth - ref.clientWidth - 5;
      let scroll = ref.scrollLeft;

      this.leftScrollAllowed = scroll > 5;
      this.rightScrollAllowed = scroll < maxScroll;

      this.scrollAllowed = this.leftScrollAllowed || this.rightScrollAllowed;
    },
  },
}
</script>

<style scoped lang="scss">
.screenshots-logo {
  margin-right: 15px;
  width: 48px;
  height: 48px;
}

.screenshots-line-top {
  display: flex;
  align-items: center;
}

.screenshots-line:not(:first-of-type) {
  margin-top: 20px;
}

.screenshots-title a {
  margin-right: 5px;
}

.screenshots-line-bottom {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 13px;
  align-items: center;
  padding-bottom: 10px;
  min-height: 299px;
}

.screenshot img {
  height: 280px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
}

.screenshots-skeleton-box {
  height: 280px;
  border-radius: 4px;
  user-select: none;
  width: 280px;
  background-color: #e4eaf4;
}

.screenshot {
  margin-right: 15px;
}

.screenshots-line-bottom .screenshot:last-of-type {
  margin-right: 0;
}

.common-scroll-btn {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  left: -18px;
  user-select: none;
}

.common-scroll-btn .svg-icons {
  position: absolute;
  top: 50%;
  font-size: 18px;
  margin: -9px 0 0;
}

.creatives-carousel {
  position: relative;
}

.creatives-carousel .common-scroll-btn:last-of-type {
  right: -18px;
  left: auto;
}

.screenshots-line-bottom::-webkit-scrollbar {
  height: 8px;
}
.screenshots-line-bottom::-webkit-scrollbar-thumb {
  background: var(--scroll-bar-screen);
}
.screenshots-line-bottom::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-bar-screen);
}

.common-app-title {
  cursor: pointer;
}

.screenshots-line.big {
  .screenshots-line-bottom {
    min-height: 399px;
  }

  .screenshot img {
    height: 380px;
  }

  .screenshots-skeleton-box {
    height: 380px;
  }
}
</style>
