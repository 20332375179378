<template>
  <div class="progress-bars-block">

    <div class="progress-bars-cols">
      <div class="progress-col">
        <div class="col-title">1-30 competitors</div>
        <progress-bar :width="calculateWidth(competitorsCount?.count, 'first')"
                      height="8px"
                      :fill-color="`background: ${calculateColor(competitorsCount?.count)}`"
                      bar-type="custom"/>
      </div>

      <div class="progress-col">
        <div class="col-title">31-70 competitors</div>
        <progress-bar :width="calculateWidth(competitorsCount?.count, 'second')"
                      height="8px"
                      :fill-color="`background: ${calculateColor(competitorsCount?.count)}`"
                      bar-type="custom"/>
      </div>

      <div class="progress-col">
        <div class="col-title">71-100 competitors</div>
        <progress-bar :width="calculateWidth(competitorsCount?.count, 'third')"
                      height="8px"
                      :fill-color="`background: ${calculateColor(competitorsCount?.count)}`"
                      bar-type="custom"/>
      </div>
    </div>

    <div class="progress-legend">
      <div class="text">Your goal is to select as many direct competitors as possible. Our recommendation is to
        choose 100 or more competitors.
        <div class="tootlip-block mobile-hidden">
          <div class="tooltip-trigger">
            <svg-icon icon="question-circle-solid"/>
          </div>
          <div class="tooltip-content">With each click of the Next button, we will suggest more applications
            that
            are probably your competitors. By using our automatic algorithms, we will be able to collect the
            semantic core that best fits your application.
          </div>
        </div>
      </div>
      <div class="competitors-added"
           :style="{color: calculateColor(competitorsCount?.count)}">
        {{ competitorsCount?.count ?? 0 }} competitors added
      </div>
    </div>

    <div class="tooltip-content-mobile mobile-visible">With each click of the Next button, we will suggest more
      applications that
      are probably your competitors. By using our automatic algorithms, we will be able to collect the
      semantic core that best fits your application.
    </div>
  </div>
</template>

<script>
import {httpRequest} from "@/api";
import ProgressBar from "@/components/UI/ProgressBar/index.vue";

export default {
  name: 'ProgressBarsHeading',
  components: {
    ProgressBar,
  },
  props: {
    appId: {
      type: Number,
    },
    currentCountry: {
      type: Object,
    },
    competitorCountChanged: 0,
  },
  data() {
    return {
      competitorsCount: 0,
    }
  },
  methods: {
    async fetchCompetitorsCount() {
      const competitorsUrl = '?app_id=' + this.appId + '&is_competitor=1';
      this.competitorsCount = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.FETCH_COMPETITORS_COUNT + competitorsUrl);
    },
    calculateColor(count) {
      if (count <= 30) {
        return 'var(--secondary-red)';
      } else if (count >= 31 && count <= 70) {
        return 'var(--secondary-orange)';
      } else if (count >= 71) {
        return 'var(--secondary-dark-green)';
      }
    },
    calculateWidth(count, number) {
      const maxColAmount = 30;
      if (number === 'first') {
        if (count >= maxColAmount) {
          return '100%';
        } else {
          return `${(count / 30) * 100}%`;
        }
      }

      if (number === 'second') {
        if (count >= 70) {
          return '100%';
        } else {
          let tempCount = count - 30;
          return `${(tempCount / 39) * 100}%`;
        }
      }

      if (number === 'third') {
        if (count >= 100) {
          return '100%';
        } else {
          let tempCount = count - 70;
          return `${(tempCount / 30) * 100}%`;
        }
      }
    },
  },
  async mounted() {
    await this.fetchCompetitorsCount();
  },
  watch: {
   async competitorCountChanged(newVal, oldVal) {
      this.competitorsCount.count = this.competitorCountChanged;
    }
  }
}

</script>

<style lang="scss" src="./styles.scss"></style>