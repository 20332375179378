<template>
  <div class="screenshots-component"
       :class="classes">

    <div class="screenshots-block">
      <template v-if="isScreenshotsDataLoaded">
        <custom-carousel
          v-if="screenshotsProcessedArray?.length > 0"
          :slides="screenshotsProcessedArray"
          :breakpoints="breakpoints"
          @onClickSlide="showSingle"
          :withNavigation="true"
        />
        <div v-else
             class="empty-screenshots">
          <div class="text">😔 No screenshots yet</div>
        </div>

        <div>

          <vue-easy-lightbox
                :visible="visibleRef"
                :imgs="imgsRef"
                :index="indexRef"
                teleport="body"
                @hide="onHide"
          ></vue-easy-lightbox>
        </div>

      </template>
      <template v-else>
        <slot name="loader">
          <div class="preloader-wrap">
            <preloader-table-small loader-size="50px"></preloader-table-small>
          </div>
        </slot>
      </template>
    </div>
  </div>
</template>

<script>
import CustomCarousel from "@/components/UI/CustomCarousel";
import VueEasyLightbox from 'vue-easy-lightbox';
import {ref, watch, getCurrentInstance, onMounted} from 'vue';
import {httpRequest} from "@/api";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";

export default {
  name: "ScreenshotsComponent",
  props: {
    // screenshotsData: {
    //   type: Array,
    // },
    currentCompetitorStore: {
      type: String,
    },
    currentCountryCode: {
      type: String,
    },
    appId: {
      type: Number,
    },
    breakpointsProps: {
      type: Object,
    },
    classes: {
      type: String,
    },
  },
  components: {
    'preloader-table-small': PreloaderTableSmall,
    VueEasyLightbox,
    'custom-carousel': CustomCarousel,
  },
  setup(props) {
    const {proxy} = getCurrentInstance();
    const apiUrls = proxy?.$ApiUrls;
    let breakpoints = ref({});
    const visibleRef = ref(false);
    const isScreenshotsDataLoaded = ref(false);
    const indexRef = ref(0);
    const screenshotsData = ref([]);
    const imgsRef = ref([]);
    let closeLocalesList = ref(0);
    let screenshotsProcessedArray = ref([]);

    function setScreenshots() {
      let imageDirection = 'vertical';

      screenshotsProcessedArray.value = screenshotsData.value?.map(item => {
        let formatImageString;
        let croppedScreenUrl;

        if (props.currentCompetitorStore === 'APP_STORE') {
          let splittedScreenUrl = item?.url?.split('/');
          let poppedItem = splittedScreenUrl.pop();
          let croppedScreen = splittedScreenUrl.join('/');
          let imageFormat = poppedItem.split('.')[1];
          let imageSizes = poppedItem.split('.')[0];
          let imageSizesSplitted = imageSizes.split('x');
          let imageWidth = parseInt(imageSizesSplitted[0]);
          let imageHeight = parseInt(imageSizesSplitted[1]);

          if (imageWidth > imageHeight) {
            imageDirection = 'horizontal';
            formatImageString = '460x244bb.' + imageFormat;
          } else {
            imageDirection = 'vertical';
            formatImageString = '244x280bb.' + imageFormat;
          }

          croppedScreen += '/' + formatImageString;
          croppedScreenUrl = croppedScreen;
        } else {
          const splittedScreenUrl = item?.url?.split('=');
          const splittedSize = splittedScreenUrl[1] ? splittedScreenUrl[1]?.split('-') : null;
          if (splittedSize === null || splittedSize?.length < 2) {

            return {
              ...item,
              croppedSrc: 'https://' + item?.url,
              direction: 'vertical',
            };

          }
          const width = splittedSize[0];
          const height = splittedSize[1];

          if (width > height) {
            imageDirection = 'horizontal';
            formatImageString = 'w460-h244';
          } else {
            imageDirection = 'vertical';
            formatImageString = 'w244-h280';
          }

          croppedScreenUrl = `${splittedScreenUrl[0]}=${formatImageString}`;
        }

        return {
          ...item,
          croppedSrc: 'https://' + croppedScreenUrl,
          direction: imageDirection,
        };
      });

      if (imageDirection === 'vertical') {
        if (props.breakpointsProps?.vertical) {
          breakpoints.value = {...props.breakpointsProps?.vertical};
        } else {
          breakpoints.value = {
            320: {
              itemsToShow: 2,
              snapAlign: 'start',
            },
            767: {
              itemsToShow: 3,
              snapAlign: 'start',
            },
            1024: {
              itemsToShow: 6,
              snapAlign: 'start',
            },
          };
        }
      } else {
        if (props.breakpointsProps?.horizontal) {
          breakpoints.value = {...props.breakpointsProps?.horizontal};
        } else {
          breakpoints.value = {
            320: {
              itemsToShow: 1,
              snapAlign: 'start',
            },
            767: {
              itemsToShow: 2,
              snapAlign: 'start',
            },
            1024: {
              itemsToShow: 2,
              snapAlign: 'start',
            },
          };
        }
      }
    }

    async function fetchScreenshotsData() {
      isScreenshotsDataLoaded.value = false;
      const url = `?country_code=${props.currentCountryCode}&app_id=${props.appId}`;
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + apiUrls.competirorsFinder.GET_LATEST_SCREENSHOTS + url);
      screenshotsData.value = [...result?.list];
      isScreenshotsDataLoaded.value = true;
    }

    const onShow = () => {
      visibleRef.value = true;
    }
    const showSingle = (carouselItem) => {
      imgsRef.value = screenshotsProcessedArray?.value?.map(item => {
        return {
          src: 'https://' + item?.url
        }
      });

      const currItemIndex = screenshotsProcessedArray?.value?.findIndex(item => item?.url === carouselItem?.url);

      indexRef.value = currItemIndex;

      onShow();
    }
    const onHide = () => (visibleRef.value = false)

   watch(() => props.appId, async (first, second) => {
    await fetchScreenshotsData();
    setScreenshots();
   });

    onMounted(async function () {
      await fetchScreenshotsData();
      setScreenshots();
    });

    return {
      visibleRef,
      indexRef,
      imgsRef,
      showSingle,
      onHide,
      breakpoints,
      closeLocalesList,
      screenshotsProcessedArray,
      isScreenshotsDataLoaded,
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
