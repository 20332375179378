<template>
  <div class="modal-message">
    <div class="modal-heading small-heading" v-if="flow === 'db'">
      <h3 class="flow-db-title">
        <preloader height="14px" v-if="!flowDbDate" style="margin-right: 10px"></preloader>
        <span v-else>{{flowDbDate}}&nbsp;</span> results for <span class="blue">&nbsp;{{ currentKeywordItem }}</span>
      </h3>
    </div>
    <div class="modal-heading small-heading" v-else>
      <h3>
        Live Search for <span class="blue">{{ currentKeywordItem }}</span>
        <span class="header-translation" v-if="currentKeywordTranslation">
          <span class="divider">/</span>
          <span class="blue">{{ currentKeywordTranslation }}</span>
        </span>
      </h3>
      <span class="store_query_correct_hint" v-if="liveSearchResults.querySugg">Results for: <b>{{liveSearchResults.querySugg}}</b></span>
    </div>
    <div class="sub-header-block">
      <div class="device-buttons">
        <template v-if="currentApp?.store?.key === 'APP_STORE'">
          <button type="button"
                  class="device-button"
                  :class="{active: liveSearchDevice === 'iphone'}"
                  @click="liveSearchDeviceChanged('iphone')">iPhone
          </button>
          <button type="button"
                  v-if="flow !== 'db'"
                  class="device-button"
                  :class="{active: liveSearchDevice === 'ipad'}"
                  @click="liveSearchDeviceChanged('ipad')">iPad
          </button>
        </template>
      </div>

      <div class="sub-header-right-side">
        <div class="compact-mode-trigger mobile-hidden"
             v-if="liveSearchResults?.hasScreenshots">
          <div class="checkboxes-block type-2">
            <input type="checkbox"
                   name="compact-mode"
                   id="compact-mode"
                   v-model="liveSearchModalExtended"
                   value="1">
            <label for="compact-mode">Compact View</label>
          </div>
        </div>

        <translate-tool :active="translateIsActive"
                        class="left-tooltip"
                        v-if="liveSearchResults?.results"
                        @translate-clicked="translateClickHandler">
          <template v-slot:text>
            Translate all applications into English
          </template>
        </translate-tool>
      </div>
    </div>

    <template v-if="!modalDataLoader">
      <preloader-table-small :loader-size="'40px'"></preloader-table-small>
    </template>
    <div v-else-if="hasError">
      Something went wrong 😱 <span @click="tryAgain" class="try-again">Try Again</span>
    </div>
    <div v-else-if="liveSearchResults?.results?.length === 0">
      {{  noAppsLabel }}
    </div>
    <div v-else
         class="live-search-block">
      <live-search-billet v-for="(item, index) in liveSearchResults?.results"
                          @live-app-clicked="liveAppClickHandler"
                          @competitor-changed="competitorChanged"
                          :key="item.id"
                          :item-data="item"
                          :item-index="item.positionIndex"
                          @open-app-modal-clicked="competitorsItemClickHandler"
                          :current-app="currentApp"
                          :show-extended="liveSearchModalExtended"></live-search-billet>

      <template v-if="liveSearchResults?.hasNextPage">
        <preloader-table-small v-if="showLiveSearchLoader"
                               :loader-size="'40px'"></preloader-table-small>

        <div v-else
             class="load-more-btn"
             @click="loadMoreLiveSearchResults">
          Load more
        </div>
      </template>
    </div>

    <details-modal v-if="showAppDetailsModal"
                   :opened="showAppDetailsModal"
                   :app-data="clickedItem"
                   @competitor-changed="modalCompetitorChanged"
                   :change-competitor-in="true"
                   :transparent-bg="true"
                   @modal-closed="detailsModalClosed"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {httpRequest} from "@/api";
import LiveSearchBillet from "@/components/Billets/LiveSearchBillet/index.vue";
import DetailsModal from "@/components/AppDetailsComponent/DetailsModal/index.vue";
import {UserAppsArrayCreator} from "@/utils/factories";
import Preloader from "@/components/UI/Preloader/index.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import translates from "@/api/modules/translates";
import axios from "axios";
import competitorState from "@/api/modules/competitor-state";

export default {
  name: "LiveSearchModal",
  components: {
    TranslateTool,
    Preloader,
    'live-search-billet': LiveSearchBillet,
    DetailsModal
  },
  emits: ['search-competitor-changed'],
  props: {
    flow: {
      type: String,
      default: 'live'
    },
    currentCountryName: {
      type: String,
    },
    currentCountryCode: {
      type: String,
    },
    currentCountryId: {
      type: Number,
    },
    currentKeywordItem: {}
  },
  data() {
    return {
      liveSearchPage: 1,
      liveSearchDevice: 'iphone',
      showLiveSearchLoader: false,
      modalDataLoader: false,
      liveSearchModalExtended: true,
      showAppDetailsModal: false,
      clickedItem: {},
      translateIsActive: false,
      flowDbDate: null,
      currentKeywordTranslation: null,
      competitorsCancelToken: axios.CancelToken.source(),
      hasError: false,
    }
  },
  async mounted() {
    this.showLiveSearchLoader = true;
    this.modalDataLoader = false;
    this.liveSearchPage = 1;

    await this.fetchCompetitorsBundle();
    await this.fetchLiveSearchResults('reset');
  },
  methods: {
    async tryAgain() {
      this.modalDataLoader = false;
      await this.fetchLiveSearchResults('reset');
    },
    async modalCompetitorChanged() {
      await this.fetchCompetitorsBundle();
      this.$store.dispatch('UPDATE_USER_LIMITS');
    },
    async competitorsItemClickHandler(item) {
      this.clickedItem = {
        id: item.id,
        title: item.title,
        store: item?.store_key,
      };

      this.showAppDetailsModal = true;
    },
    async fetchLiveSearchResults(mode = 'increment') {
      if (mode === 'increment') {
        this.modalDataLoader = true;
      } else {
        this.showLiveSearchLoader = true;
      }

      await this.setAppsList();

      this.liveSearchDevice = this.currentApp?.store?.key !== 'APP_STORE' ? 'mobile' : this.liveSearchDevice;

      let url = process.env.VUE_APP_API_URL
       + this.$ApiUrls.keywordsTracking.APPLICATIONS_LIVE_SEARCH
       + '?country_code=' + this.currentCountryCode
       + '&device=' + this.liveSearchDevice
       + '&store_key=' + this.currentApp?.store?.key
       + '&fields=id,store_key,logo,title,screenshots,rating_num,votes_num,genre,developer_name&page=' + this.liveSearchPage
       + '&phrase=' + this.currentKeywordItem;

      if (this.flow === 'db') {
        url += '&flow=db';
      }

      const result = await httpRequest('GET', url);

      let concatedArrays;
      let hasScreenshots = false;
      let hasNexPage;
      let resultArrays = [];

      if (!result?.results) {
        resultArrays = this.liveSearchResults?.results;
        hasNexPage = false;
      } else {
        concatedArrays = result?.results;
        resultArrays = this.liveSearchResults?.results ? [...this.liveSearchResults.results, ...result?.results] : result?.results;
        hasNexPage = result?.hasNexPage;
      }

      this.flowDbDate = result?.db_date;
      this.hasError = result.has_error;

      if (mode === 'increment') {
        concatedArrays = [...resultArrays];
      } else {
        this.modalDataLoader = false;
      }

      concatedArrays?.forEach(item => {
        if (item?.data?.screenshots?.length > 0) {
          hasScreenshots = true;
        }
      });

      const tempLiveSearchResults = {
        hasNexPage,
        querySugg: result.query_sugg,
        results: concatedArrays,
        hasScreenshots,
      };

      this.$store.dispatch('keywordsTracking/SET_LIVE_SEARCH_RESULTS', tempLiveSearchResults);
      await competitorState.setActiveCompetitors();

      this.modalDataLoader = true;
      this.showLiveSearchLoader = false;
    },
    async setAppsList() {
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS_NEW);
      this.$store.dispatch('SET_MY_APPS', UserAppsArrayCreator(result) ?? []);
    },
    async fetchCompetitorsBundle() {
      await competitorState.fetchCompetitors();
    },
    async loadMoreLiveSearchResults() {
      this.liveSearchPage++;
      this.showLiveSearchLoader = true;
      await this.fetchLiveSearchResults();
    },
    async competitorChanged(newType, id, oldType = null) {
      await competitorState.competitorChanged(
          newType,
          id,
          oldType,
          this.currentCountry?.id,
      );
    },
    async liveAppClickHandler(app) {
      if (app.mode === 'remove') {
        this.showDeleteAppModal = true;
        this.appIdToDelete = {...app};
      } else {
        let formData = new FormData();
        formData.append('application_id', app.application_id);
        formData.append('country_code', this.currentCountryCode);
        await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS, formData);

        const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS_NEW);
        this.$store.dispatch('SET_MY_APPS', UserAppsArrayCreator(result) ?? []);
        this.$store.dispatch('keywordsTracking/SET_LIVE_SEARCH_ACTIVE_APPS', {id: app.application_id, mode: app.mode});
        this.$store.dispatch('UPDATE_USER_LIMITS');
      }
    },
    async deleteAppById() {
      let formData = new FormData();
      formData.append('_method', 'delete');
      await httpRequest('DELETE', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS + '/' + this.appIdToDelete.application_id);

      this.closeModal('showDeleteAppModal');
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.myApps.MY_APPLICATIONS_NEW);
      this.$store.dispatch('SET_MY_APPS', UserAppsArrayCreator(result) ?? []);
      this.$store.dispatch('keywordsTracking/SET_LIVE_SEARCH_ACTIVE_APPS', {
        id: this.appIdToDelete.application_id,
        mode: this.appIdToDelete.mode
      });
    },
    async liveSearchDeviceChanged(device) {
      this.liveSearchDevice = device;
      this.liveSearchPage = 1;
      this.modalDataLoader = false;
      await this.fetchLiveSearchResults('reset');
    },
    detailsModalClosed() {
      this.showAppDetailsModal = !this.showAppDetailsModal;
      setTimeout(() => {
        document.body.style.overflow = 'hidden';
      }, 350);
    },
    async translate() {
      let phrases = this.liveSearchResults?.results
        .filter(item => item.data.translate === undefined || item.data.translate === null)
        .map(item => item.data.title);
      let translations = await translates.translatePhrases(this.currentApp?.store?.key, phrases);
      this.liveSearchResults?.results.forEach((item) => {
        item.data.translate = translations[item.data.title] ?? item.data.translate;
      });
    },
    async translateClickHandler() {
      if (this.translateIsActive) {
        this.liveSearchResults?.results.forEach((item) => {
          item.data.translate = null;
        });
        this.currentKeywordTranslation = null;
        this.translateIsActive = false;
        return;
      }

      await this.translate();
      this.currentKeywordTranslation = await translates.translateKeyword(this, this.currentApp?.store?.key, this.currentKeywordItem);
      this.translateIsActive = true;
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'promiseSettledData',
      'myApps',
    ]),
    ...mapGetters('keywordsTracking', [
      'liveSearchResults',
      'competitorsResults',
    ]),
    noAppsLabel() {
      const storeName = this.currentApp?.store?.key === "APP_STORE" ? "App store" : "Google Play"
      return `No apps in ${storeName} for this keywords...`
    }
  },
  watch: {
    liveSearchResults(newVal, oldVal) {
      let isIdsEqual = JSON.stringify(newVal?.results?.map(item => item.data.id)) === JSON.stringify(oldVal?.results?.map(item => item.data.id));
      if (this.liveSearchResults?.results?.length > 0 && this.translateIsActive && !isIdsEqual) {
        this.translate();
      }
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
<style scoped lang="scss">
.flow-db-title {
  display: flex;
  align-items: center;
}
</style>