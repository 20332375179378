<template>
  <div>
    <template v-if="items?.length > 0">
      <div class="custom-data-table">

        <div class="scroll-part">
          <table>
            <thead>
            <tr>
              <th v-for="(headerItem, index) in headers"
                  :key="index"
                  :class="[headerItem.align ? [headerItem.align] : 'left', headerItem.headingClass]"
                  :style="[{ width: headerItem.width ? headerItem.width : '', minWidth: headerItem.minWidth ? headerItem.minWidth : ''}]">

                <div class="inner-content">
                  <div class="inner-content-wrap">
                  <span>
                <template v-if="headerItem.tooltipText">
                  <custom-tooltip :width="headerItem.tooltipWidth"
                                  :padding-classes="'padding: 4px 0;'">
                    <template v-slot:slotTrigger>
                      {{ headerItem.text }}
                    </template>
                    <template v-slot:slotContent>
                      <span v-html="headerItem.tooltipText"></span>
                    </template>
                  </custom-tooltip>
                </template>
                <template v-else>{{ headerItem.text }}</template>
            </span>
                  </div>
                </div>

              </th>
            </tr>
            </thead>

            <tbody>
            <tr class="loading-indicator"
                :class="[{active: loading}]">
              <div class="loading">
                <div class="loading_line_wrapper">
                  <div class="loading_line">
                    <div class="loading_line_inner loading_line_inner--1"></div>
                    <div class="loading_line_inner loading_line_inner--2"></div>
                  </div>
                </div>
              </div>
            </tr>
            <tr v-for="(item, index) in items"
                :key="index">

              <slot name="items" :item="item">
                <!-- Fallback content -->
                items list
              </slot>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TableCheckbox from "@/components/UI/TableCheckbox/index";
import CustomDataTableSelect from "@/components/Forms/CustomDataTableSelect/index";

export default {
  name: "CustomDataTable",
  components: {
    'data-table-checkbox': TableCheckbox,
    'custom-data-table-select': CustomDataTableSelect,
  },
  emits: ['check-all-clicked', 'update-sort'],
  props: {
    fixedWidth: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array
    },
    items: {
      type: Array
    },
    itemsPerPage: {
      type: Number
    },
    rowsPerPageItems: {
      type: Array
    },
    pagination: {
      type: Object
    },
    sortingMeta: {
      type: Object
    },
    sortable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    checkedTableItemsCount: {
      type: Number,
      required: false,
    },
    hasSelectionArray: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      deleteAllCheckboxValue: false
    }
  },
  methods: {
    selectClicked(e) {
      this.$emit('update-sort', {
        per_page: e.value,
      });
    },
  },
}
</script>

<style src="./styles.scss" lang="scss"></style>