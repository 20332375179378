<template>
  <div class="modal-details-upper">
    <div class="details-block">
      <div class="app-logo">
        <template v-if="data?.app?.logo">
          <img :src="cropImage(data?.app?.logo, '100x100', data?.app?.store)" :alt="data?.app?.title">
        </template>
        <template v-else>
          <div class="empty-logo"></div>
        </template>
      </div>

      <div class="app-details">
        <template v-if="isLoaded">
          <div class="modal-competitors-block mobile-visible">
            <div class="competitor-item non-competitor"
                 :class="{active: currCompetitorType === 'non-competitor'}"
                 v-on="currCompetitorType !== 'non-competitor' ? {click: () => competitorChanged('non-competitor', data?.app?.id, 'competitor')}: {}">
              Non-competitor
            </div>
            <div class="competitor-item competitor"
                 :class="{active: currCompetitorType === 'competitor'}"
                 v-on="currCompetitorType !== 'competitor' ? {click: () => competitorChanged('competitor', data?.app?.id, 'non-competitor')}: {}">
              Competitor
            </div>
          </div>
          <div class="app-name">{{ data?.app?.title ?? '' }}</div>
          <div class="app-subtitle">
            <span class="subtitle-item">{{ data?.app?.developer?.name ?? '' }}</span>
            <span class="title-separator"></span>
            <span class="subtitle-item">{{ data?.app?.sub_title ? sanitizeString(data?.app?.sub_title) : '' }}</span>
            <span class="title-separator"></span>
            <a class="subtitle-link"
               :href="data?.app?.url"
               target="_blank">View in {{ data?.app?.store === 'APP_STORE' ? 'App Store' : 'Google Play' }}</a>
          </div>
          <div class="bottom-content">
            <div class="localization-dropdown">
              <div class="localization-title">Localization:</div>
              <custom-dropdown v-if="topLocalesData?.length > 0"
                               :close-dropdown="closeLocalesList">
                <template v-slot:header>
                  {{ currentPropLocaleComputed?.title }}
                </template>
                <template v-slot:content>
                  <div class="content-items-list">
                    <div class="dropdown-item"
                         @click="localeItemClicked(item)"
                         v-for="item in topLocalesData">
                      {{ item.value }}
                      <template v-if="item.countryValue">
                        ({{ item.countryValue }})
                      </template>
                    </div>
                  </div>
                </template>
              </custom-dropdown>
            </div>

            <div class="modal-competitors-block mobile-hidden">
              <div class="competitor-item non-competitor"
                   :class="{active: currCompetitorType === 'non-competitor'}"
                   v-on="currCompetitorType !== 'non-competitor' ? {click: () => competitorChanged('non-competitor', data?.app?.id, 'competitor')}: {}">
                Non-competitor
              </div>
              <div class="competitor-item competitor"
                   :class="{active: currCompetitorType === 'competitor'}"
                   v-on="currCompetitorType !== 'competitor' ? {click: () => competitorChanged('competitor', data?.app?.id, 'non-competitor')}: {}">
                Competitor
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="skeleton-wrap">
            <div class="skeleton long-skeleton-box"></div>
            <div class="skeleton medium-skeleton-box"></div>
            <div class="skeleton short-skeleton-box"></div>
          </div>
        </template>
      </div>
    </div>
    <div class="aso-score-block">
      <div class="aso-score"
           :class="[setAsoScoreColor(data?.score?.score)]">
        <template v-if="isLoaded">
          {{ data?.score?.score ?? 0 }}
        </template>
        <template v-else>
          <div class="skeleton aso-score"></div>
        </template>
      </div>
      <div class="text">ASO score</div>
    </div>
  </div>
</template>

<script>
import CustomDropdown from "@/components/UI/CustomDropdown";
import SvgIcon from "@/components/UI/SvgIcon";
import {ref, computed, watch} from 'vue';
import {cropImage, sanitizeString} from "@/utils/utils";

export default {
  name: "ModalAppUpperDetails",
  props: {
    data: {
      type: Object,
    },
    isLoaded: {
      type: Boolean,
      default: false,
    },
    topLocalesData: {
      type: Array,
    },
    currentPropLocale: {
      type: Object,
    },
    competitorType: {
      type: String,
    },
    competitorId: {
      type: Number,
    },
  },
  components: {
    SvgIcon,
    'custom-dropdown': CustomDropdown,
  },
  emits: ['locale-changed', 'competitor-changed'],
  setup(props, {emit}) {
    let closeLocalesList = ref(0);
    let currentLocale = ref({
      title: '',
      code: '',
    });

    let currCompetitorType = ref('');
    currCompetitorType.value = props?.competitorType;
    const currentPropLocaleComputed = computed(() => props.currentPropLocale);

    function setAsoScoreColor(score) {
      if (score >= 0 && score < 31) {
        return 'red';
      } else if (score >= 31 && score < 41) {
        return 'orange';
      } else if (score >= 41 && score < 61) {
        return 'yellow';
      } else if (score >= 61 && score < 81) {
        return 'light-green';
      } else if (score >= 81) {
        return 'green';
      } else {
        return '';
      }
    }

    function localeItemClicked(item) {
      closeLocalesList.value++;
      currentLocale.value = {...item, title: item.value};
      emit('locale-changed', currentLocale.value);
    }

    function competitorChanged(type, id, competitorType) {
      if (!currCompetitorType?.value) {
        emit('competitor-changed', {
          type,
          id: props?.competitorId,
          competitorType: null
        });
      } else {
        emit('competitor-changed', {type, id, competitorType});
      }
      currCompetitorType.value = type;
    }

    watch(() => props?.competitorType, function (newValues, oldValues) {
      currCompetitorType.value = newValues;
    });

    return {
      setAsoScoreColor,
      localeItemClicked,
      cropImage,
      sanitizeString,
      competitorChanged,
      closeLocalesList,
      currentLocale,
      currentPropLocaleComputed,
      currCompetitorType,
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>