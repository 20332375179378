export const competitorsRankingKeywordsTableConfig = {
    headers: [
        {
            text: 'Keywords',
            value: 'keyword',
            align: 'left',
            width: '75%',
            textWidth: '250px',
            sortable: false,
        },
        {
            text: 'App Rank',
            value: 'rank',
            width: '25%',
            textWidth: '25%',
            align: 'right',
            sortable: false,
        },
    ],
}