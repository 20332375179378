export const competitorsCategoryRankingTableConfig = {
    headers: [
        {
            text: 'Category',
            value: 'category',
            align: 'left',
            width: '47%',
            textWidth: '250px',
            sortable: false,
        },
        {
            text: 'Price',
            value: 'top',
            width: '28%',
            textWidth: '28%',
            align: 'left second-col',
            sortable: false,
        },
        {
            text: 'Position',
            value: 'value',
            width: '25%',
            textWidth: '25%',
            align: 'right',
            sortable: false,
        },
    ],
}